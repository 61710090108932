$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();
    handleBlockquote();

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).on("load", function() {

    handleFooter();
    $(window).trigger('resize');
});

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-carousel').owlCarousel({
        items: 1,
        margin: 30,
        stagePadding: 60,
        loop: false,
        dots: false,
        responsive: {
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4,
                mouseDrag: false
            },
            1200: {
                items: 5,
                mouseDrag: false
            },
            1400: {
                items: 4,
                stagePadding: 0,
                mouseDrag: false
            }
        }
    })
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength == 4) {
            $(item).addClass('four-image');
        } else if (galleryLength >= 5) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {

    var placeholder = $("figure.figure-progressive");

    placeholder.each(function() {
        var small = this.querySelector('.img-small');

        //load small image and show it
        var img = new Image();
        img.src = small.src;
        img.onload = function () {
            small.classList.add('loaded');
        };

        //load large image
        var imgLarge = new Image();
        imgLarge.src = this.dataset.large;
        imgLarge.srcset = this.dataset.srcset;
        imgLarge.onload = function () {
            imgLarge.classList.add('loaded');
        };
        this.appendChild(imgLarge);

    });
};

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function handleBlockquote() {
    $('blockquote').addClass('blockquote');
}