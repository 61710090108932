(function($){
	$(function() {

		$('.bestsellers').each(function(){
			if($(this).find('.gallery a').length === 0) {
				$(this).hide();
			}
		});
	});
})(jQuery);
